import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Link } from '~/components'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import { usePage } from '~/hooks'
import styles from './UniqueSellingPointsIcons.module.css'
import { UniqueSellingPointsIconsProps } from './UniqueSellingPointsIcons.types'

export const UniqueSellingPointsIcons = ({
  icons,
  width = 'wide',
  mobileLayout = 'grid',
}: UniqueSellingPointsIconsProps) => {
  const page = usePage()
  const isRTM = page.brand === 'rtm'
  const isGridLayout = mobileLayout === 'grid'

  return (
    <SectionContainer width={width} wrapperClassName='max-xl:lg:!px-10'>
      <div
        className={classNames(
          'flex lg:py-8 py-4 max-w-screen-3xl mx-auto justify-between',
          {
            'max-lg:grid grid-cols-2 max-lg:gap-x-4 max-lg:gap-y-10':
              isGridLayout,
            '!justify-center lg:gap-24 gap-4': !isRTM,
            'max-lg:flex-col max-lg:gap-y-8': !isGridLayout,
          }
        )}>
        {!!icons.length &&
          icons.map((icon) => {
            if (!icon) {
              return null
            }
            const {
              title,
              description,
              iconImage,
              hiddenOnMobile,
              iconLink,
              openInNewTab,
            } = icon
            return (
              <div
                key={title}
                className={classNames(
                  'flex items-center flex-col xl:max-w-[248px] w-full justify-self-center relative',
                  {
                    'max-lg:!hidden': hiddenOnMobile,
                    [styles.wrapper]: isGridLayout,
                  }
                )}>
                {iconImage?.src && (
                  <Image
                    src={getImageSrc(iconImage.src, '50')}
                    addSrcSet={false}
                    alt={iconImage.altText}
                    width={iconImage.width}
                    height={iconImage.height}
                  />
                )}
                <Typography
                  tag={TypographyTag.p}
                  variant={
                    isRTM
                      ? TypographyVariant.Heading5
                      : TypographyVariant.BodyXLargeBold
                  }
                  className={classNames('mt-2.5 text-center', styles.title)}>
                  {title}
                </Typography>
                <Typography
                  tag={TypographyTag.p}
                  className={classNames(
                    'mt-1 text-primary-muted text-center',
                    styles.description
                  )}
                  variant={TypographyVariant.BodyRegular}>
                  {description}
                </Typography>
                {iconLink?.url && (
                  <Link
                    href={iconLink?.url}
                    className='absolute inset-0'
                    target={openInNewTab ? '_blank' : ''}>
                    <VisuallyHidden>about {title}</VisuallyHidden>
                  </Link>
                )}
                {iconLink?.displayName && iconLink?.url && (
                  <Link
                    href={iconLink?.url}
                    className='z-20'
                    target={openInNewTab ? '_blank' : ''}>
                    <Typography
                      tag={TypographyTag.p}
                      variant={TypographyVariant.BodyRegular}
                      className={classNames('!underline', styles.linkText)}>
                      {iconLink?.displayName}
                    </Typography>
                  </Link>
                )}
              </div>
            )
          })}
      </div>
    </SectionContainer>
  )
}
